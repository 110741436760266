import React from 'react';

export const Benefit = ()=>{
    return (
        <div className='benefit'>
            <h2>BENEFITS OF JOINING</h2>
            <div className='benefit-item-wrap'>
                <p className='benefit-item'>Opportunity to get funding from TDV and HTX Ventures</p>
                <p className='benefit-item'>Access to industry partner network</p>
                <p className='benefit-item'>Visibility among leading VC partners</p>
                <p className='benefit-item'>
                    <span>Win from a prize pool of $500,000 worth TRX Prize Pools *</span>
                </p>
                <p className='benefit-item'>
                    <span>Up to $150,000 energy prizes on Justlend as an energy incentive for S7 winners.</span>
                    <p className='benefit-note'>* THE AWARD OF A PRIZE TO A POTENTIAL WINNER IS SUBJECT TO VERIFICATION OF IDENTITY, QUALIFICATIONS AND ROLE OF THE POTENTIAL WINNER IN THE CREATION OF THE SUBMISSION. No submission or submitter shall be deemed a winning submission or winner until their post-competition prize affidavits have been completed and verified, even if prospective winners have been announced verbally or on the competition website. The final decision to designate a winner shall be made by the Sponsor and/or Administrator. It should be noted that all non-US based winners will be required to establish a verified account on HTX, attain Level 3 status and provide their TRC20 TRX address from said platform in order to receive their prizes. Winners who are U.S. persons are required to provide a KYC verified Coinbase or Kraken wallet address and accept an equivalent prize distribution in the form of USDT or USDC.”</p> 
                </p>
            </div>
        </div>
    )
}