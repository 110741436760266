import React from 'react';

export const HackatronButton = ({btnText,cls,handleClick,showArrow=true})=>{
    return <div className={`hackatrons5-button ${cls}`} onClick={handleClick}>
        <span className='mr-2'>{btnText}</span>
        {showArrow&&<div className='hackatrons5-button-icon'>
                <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.825 7.875H2.25V9.375H12.825L10.5 11.7L11.55 12.75L15.675 8.625L11.625 4.5L10.575 5.55L12.825 7.875Z" fill="currentColor"/>
                </svg>
        </div>}
    </div>
}