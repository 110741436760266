import React, { useState } from 'react';
import {HackatronButton} from './button';
import {CgClose} from "react-icons/all";
//TODO 点击弹窗
export const HackaTronVideo = ({videoUrl,cls,videoCover,btnShow,btnConfig})=>{
    const [show,setShow] = useState(false);
    return <>
    <div className={`hackatron-video d-flex flex-row ${cls}`}>
        <div className='hackatron-video-title'>
            <h2>Let’s BUIDL the future of web3 together</h2>
            <p>Welcome to HackaTRON, where pioneers gather to redefine the digital landscape. Join us on a journey unlocking the power of blockchain. Explore Gaming, AI, DeFI, and more. Together, we BUIDL the future of Web3.</p>
        </div>
        <div className='hackatron-video-box'>
            <img className='hackatron-video-cover' src={videoCover}/>
            <div className='hackatron-video-play' onClick={()=>{
                setShow(true)
            }}/>
            {btnShow&&<HackatronButton showArrow={false} handleClick={()=>{
                window.open(btnConfig.url,"__blank")
            }} cls={'hackatron-video-register'} btnText={btnConfig.btnText}/>}
        </div>
        
    </div>
    {show&&<div className='hackatron-video-modal'>
    <div className='hackatron-video-modal-wrap'>
        <img className='hackatron-video-modal-cover' src={videoCover}/>
        <iframe iframe id="player" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="Flow To The Future Hackathon" width="940" height="528"
        className='hackatron-video-modal-content' src={videoUrl} />
    </div>
    <div className='hackatron-video-modal-close' onClick={()=>setShow(false)} >
        <CgClose size="35px"/>
    </div>
</div>}</>
}