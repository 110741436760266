import React, {useState} from "react"
import web3 from "../../images/tracks/web3.svg";
import defi from "../../images/tracks/defi.svg";
import integration from "../../images/tracks/integration.svg";
import artistry from '../../images/tracks/artistry.svg';
import builder from "../../images/tracks/builder.png";

export const Trackss5 = () => {
    const tracks = [
        {
            trackImg: artistry,
            title: "Artistry",
            text: "Step into a dynamic realm where entertainment converges with blockchain technology. Delve into the future of gaming, crafting groundbreaking game ideas that redefine the industry. Simultaneously, embrace the creator economy and the limitless possibilities of NFT applications.",
        },
        {
            trackImg: builder,
            title: "Builder",
            text: "A new track for returning projects that continued development on TRON/BTTC and create significant updates for their project."    
        },
        {
            trackImg: web3,
            title: "Web3",
            text: "The future of the Web is in your hands. The judges are looking to developers for a contribution that will help transition this new technology and bring it to fruition."
        },
        {
            trackImg: defi,
            title: "DeFi",
            text: "DeFi continues to be a crucial movement for the crypto space as it enables financial services to operate in an open, accessible, and transparent digital form. Looking for the next innovative DeFi solution that could provide accessible financial services to billions of people. "
        },
        {
            trackImg: integration,
            title: "Integration",
            text: "The Integration Track prompts participants to integrate advanced protocols into the TRON ecosystem, urging them to incorporate existing protocols, dapps, or services on TRON/BTTC into their applications or create impactful features/tools for enhancing applications within the TRON/BTTC ecosystems.",
            new:true
        },
    ];

    const [carouselCounter, setCarouselCounter] = useState(0);
    let movementAmount = 100;

    const moveCarousel = (direction) => {
        if (direction === "right") {
            let total = carouselCounter - movementAmount;
            document.getElementById("tracks-s5-carousel").style.transform = 'translateX(' + total + 'vw)';
            setCarouselCounter(total);
        } else if (direction === "left") {
            let total = carouselCounter + movementAmount;
            document.getElementById("tracks-s5-carousel").style.transform = 'translateX(' + total + 'vw)';
            setCarouselCounter(total);
        }
    }
    const hideLeftArrow = function (counter) {
        return counter === 0 ? " d-none " : "";
    }
    const hideRightArrow = function (counter) {
        return counter === -400 ? " d-none " : "";
    }

    
    return (
        <div className="tracks-s5-wrapper">
            <div className="tracks-s5-title text-center">
                <h2>Tracks</h2>
                <p>Global hacker community platform</p>
            </div>
            <div style={{position:"relative"}}>
                <div onClick={() => {
                    moveCarousel('left')
                }} className={hideLeftArrow(carouselCounter) + 'tracks-s5-wrapper-arrow-left'} />
                <div onClick={() => {
                    moveCarousel('right')
                }} className={hideRightArrow(carouselCounter) + 'tracks-s5-wrapper-arrow-right'} />
                <div className="tracks-s5 d-flex flex-wrap justify-content-start" id="tracks-s5-carousel">
                    {
                        tracks.map((track => {
                            return <div className={`tracks-s5-card ${track.new?"tracks-s5-card-new":""} ${track.title}`} key={"tracks-key-" + track.title}>
                                <div
                                    className="tracks-s5-card-img align-items-center d-flex justify-content-center justify-content-md-start">
                                    <img src={track.trackImg} className="img-fluid"
                                        alt={track.title + " track"}/>
                                    <h4 className="d-inline-block">{track.title}</h4>
                                </div> 
                                <p>{track.text}</p>
                            </div>
                        }))
                    }
                </div>
            </div>
        </div>
    )
}
