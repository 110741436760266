import React, {useState} from "react"
import cryptozombies from "../../images/developers/cryptozombies-logo.png";
import quicknode from "../../images/developers/quicknode-logo.png";
import tatum from "../../images/developers/tatum-logo.png";
import cookbook from '../../images/developers/cookbook-logo.png';

export const Developers = () => {
    const developer = [
        {
            trackImg: cryptozombies,
            title: "Crypto Zombies",
            text: "Learn to deploy on TRON by building a simple game.",
            link:"https://cryptozombies.io/en/tron"
        },
        {
            trackImg: quicknode,
            title: "QuickNode",
            text: "How to Deploy and Interact with Smart Contracts on TRON Using JavaScript.",
            link:"https://www.quicknode.com/guides/other-chains/tron/how-to-deploy-and-interact-with-smart-contracts-on-tron"
        },
        {
            trackImg: tatum,
            title: "Tatum",
            text: " How to access Tron nodes and make simple calls to the Tron network using Tatum.",
            link:"https://www.youtube.com/watch?v=8giwTXshwZc"
        },
        {
            trackImg: cookbook,
            title: "Cookbook.dev",
            text: "An open-source marketplace to upload, find, and deploy smart contracts for free. Also, try ChefGPT, Cookbook's solidity smart contract AI co-pilot and coding assistant.",
            link:"https://www.cookbook.dev/chains/Bittorrent#:~:text=Overview-,Resources,-ChefGPT"
        }
    ];

    const [carouselCounter, setCarouselCounter] = useState(0);
    let movementAmount = 100;

    const moveCarousel = (direction) => {
        if (direction === "right") {
            let total = carouselCounter - movementAmount;
            document.getElementById("developer-carousel").style.transform = 'translateX(' + total + 'vw)';
            setCarouselCounter(total);
        } else if (direction === "left") {
            let total = carouselCounter + movementAmount;
            document.getElementById("developer-carousel").style.transform = 'translateX(' + total + 'vw)';
            setCarouselCounter(total);
        }
    }
    const hideLeftArrow = function (counter) {
        return counter === 0 ? " d-none " : "";
    }
    const hideRightArrow = function (counter) {
        return counter === -300 ? " d-none " : "";
    }

    
    return (
        <div className="developer-wrapper">
            <div className="developer-title text-center">
                <h2>Educational Resources</h2>
                {/* <p>Global hacker community platform</p> */}
            </div>
            <div style={{position:"relative"}}>
                <div onClick={() => {
                    moveCarousel('left')
                }} className={hideLeftArrow(carouselCounter) + 'developer-wrapper-arrow-left'} />
                <div onClick={() => {
                    moveCarousel('right')
                }} className={hideRightArrow(carouselCounter) + 'developer-wrapper-arrow-right'} />
                <div className="developer-box d-flex justify-content-between" id="developer-carousel">
                    {
                        developer.map((track => {
                            return <div className={`developer-box-card`}>
                                <div
                                    className="developer-box-card-img">
                                    <img src={track.trackImg} className="img-fluid"
                                        alt={track.title + " developer"}/>
                                </div> 
                                <a href={track.link} target="__blank">
                                    {track.title}
                                </a>
                                <p>{track.text}</p>
                            </div>
                        }))
                    }
                </div>
            </div>
        </div>
    )
}
