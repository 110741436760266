import React ,{useEffect, useState} from 'react';
import chaingpt from '../../images/hackatrons6/chaingpt.png';
import solidus from '../../images/hackatrons6/solidus.png';
import ankr from '../../images/hackatrons6/ankr.png';
const CDN_PREFIX = 'https://cms.trondao.org'
export const BroughtToYouBy = ({data})=>{
    const [partner,setPartner] = useState([]);
    const [dataPartner,setDataPartner] = useState([]);
    const [sponsor,setSponsor] = useState([]);
    const [securityPartner,setSecurityPartner] = useState([]);
    const [prensentedBy,setPrensentBy] = useState([])
    useEffect(()=>{
        let partner = [];
        let dataPartner = [];
        let sponsor = [];
        let securityPartner = [];
        let prensentedBy = [];
        if(data.length){
            for(let item of data){
                if(item.node.partnerInfo.partnerLogo && item.node.partnerInfo.partnerLogo.sourceUrl.indexOf(CDN_PREFIX)==-1){
                    item.node.partnerInfo.partnerLogo.sourceUrl = CDN_PREFIX + item.node.partnerInfo.partnerLogo.sourceUrl
                }
                switch(item.node.partnerInfo.partnerType){
                    case 'partner':
                        partner.push(item.node);
                        break;
                    case 'dataPartner':
                        dataPartner.push(item.node);
                        break;
                    case 'sponsor':
                        sponsor.push(item.node);
                        break;
                    case 'securityPartner':
                        securityPartner.push(item.node);
                        break;
                    case 'PrensentedBy':
                        prensentedBy.push(item.node);
                        break;    
                }
            }
            setPartner(partner);
            setDataPartner(dataPartner);
            setSponsor(sponsor);
            setSecurityPartner(securityPartner)
            setPrensentBy(prensentedBy)
        }
    },[data])
    return <div className='brought2you'>
        <div className='first-load'>
            <div className='brought2you-title-wrap'>
                <div className='brought2you-title-bg'>
                    Backers
                </div>
                {/* <h2 className='brought2you-title'>
                    Brought to you by
                </h2> */}
                <h2 className='brought2you-title'>
                    Prensented By
                </h2>
            </div>
            <div className='brought2you-sponsor'>
                {
                    prensentedBy.map(item=>{
                        return  <div className='brought2you-sponsor-img' key={item.id}>
                            <a href={item.partnerInfo.partnerUrl} target='__blank'>
                                <img src={item.partnerInfo.partnerLogo?.sourceUrl}/>                            
                            </a>    
                        </div>
                    })
                }
            </div>
        </div>
        <div className='first-load'>
            <h2 className='brought2you-title pos-unset'>
            Sponsor
            </h2>
            <div className='brought2you-sponsor'>
                {/* <div className='brought2you-sponsor-img'>
                    <a href='http://chaingpt.org/' target='__blank'>
                        <img src={chaingpt}/>
                    </a>
                </div>
                <div className='brought2you-sponsor-img'>
                    <a href='https://www.aitech.io/' target='__blank'>
                        <img src={solidus}/>
                    </a>
                </div>
                <div className='brought2you-sponsor-img'>
                    <a href='https://www.ankr.com/' target='__blank'>
                        <img src={ankr}/>
                    </a>
                </div> */}
                {
                    sponsor.map(item=>{
                        return  <div className='brought2you-sponsor-img-sm' key={item.id}>
                            <a href={item.partnerInfo.partnerUrl} target='__blank'>
                                <img src={item.partnerInfo.partnerLogo?.sourceUrl}/>
                            </a>    
                        </div>
                    })
                }
            </div>
        </div>
        <div className='first-load'>
            <h2 className='brought2you-title pos-unset'>
                Partners
            </h2>
            <div className='brought2you-sponsor'>
                {
                    partner.map(item=>{
                        return  <div className='brought2you-sponsor-img-sm' key={item.id}>
                            <a href={item.partnerInfo.partnerUrl} target='__blank'>
                                <img src={item.partnerInfo.partnerLogo.sourceUrl}/>
                            </a>    
                        </div>
                    })
                }
            </div>
        </div>
        <div className='first-load'>
            <h2 className='brought2you-title pos-unset'>
                Data Platform Partner
            </h2>
            <div className='brought2you-sponsor'>
                {
                    dataPartner.map(item=>{
                        return  <div className='brought2you-sponsor-img-sm' key={item.id}>
                            <a href={item.partnerInfo.partnerUrl} target='__blank'>
                                <img src={item.partnerInfo.partnerLogo.sourceUrl}/>
                            </a>    
                        </div>
                    })
                }
            </div>
        </div>
        <div className='first-load'>
            <h2 className='brought2you-title pos-unset'>
                Security Partner
            </h2>
            <div className='brought2you-sponsor'>
                {
                    securityPartner.map(item=>{
                        return  <div className='brought2you-sponsor-img-sm' key={item.id}>
                            <a href={item.partnerInfo.partnerUrl} target='__blank'>
                                <img src={item.partnerInfo.partnerLogo.sourceUrl}/>
                            </a>    
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}