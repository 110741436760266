import React from 'react';
import huobi from '../../images/hackatrons5/highlights-huobi.png';
import tron from '../../images/hackatrons5/highlights-tron.png';

export const Highlights = ()=>{
    return <div className='highlights'>
        <div className="w-100 mx-auto" style={{maxWidth: '1920px'}}>
            <h2 id="highlights" class="w-100 text-center section-header font-weight-bold pt-5 text-white">Highlights</h2>
            <div className="section-description mx-auto" style={{maxWidth: '982'}}>Winning projects will have the opportunity to obtain capital funding from blockchain focused venture firms, TRON DAO Ventures and Huobi Ventures.</div>
        </div>
        <div className="mx-auto w-100" style={{maxWidth: '1920px'}}>
            <div className="d-flex flex-wrap justify-content-center" style={{gap:'40px'}}>
                <div className="tracks-s5-partner-logo">
                    <img src={tron} alt="Tron Partner Logo" className="img-fluid" style={{maxWidth:"273px"}}/>
                </div>
                <div className="tracks-s5-partner-logo">
                    <img src={huobi} alt="Huobi Partner Logo" className="img-fluid" style={{maxWidth:"80vw",width:'458px'}}/>
                </div>
            </div>
        </div>
    </div>
}